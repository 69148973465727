#header {
    background-color: #0678BE;
    /* position: fixed;
    top: 0;
    height: 60px;
    width: 100%;
    z-index: 3; */
}

.NavClass{
    
    list-style: none;
    padding: 10px;
    width: 1209px;
}
.NavClass li {
    display: inline-block;
    text-decoration: none;
    margin-right: 20px;
    color: black;
}

.NavClass li a{
    text-decoration: none;
    color: #94C6E5;
    font-weight: 500;
}

.h2Class{
    color:#0678BE;
    margin-bottom:30px;
    /* margin-top: 70px; */
}

.NavClass li a.active{
    color:#ffffff;
    position: relative;
}

.NavClass li a.link-service.active::before {
    position: absolute;
    content: '';
    bottom: -22px;
    /* border-top: 36px solid transparent; */
    border-bottom: 8px solid white;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    left: 40%;
    right: auto;
}

.NavClass li a.link-dropdown {
    color:#0678BE;
}

.NavClass li a.link-dropdown.active {
    color:green;
}



.NavClass li a.signout {
    background-color: #7293BC;
    color:#F2FAFF;
    padding: 22px;
}

.NavClass .nav-link {
    padding: 0 20px 0 0;
}

.NavClass .dropdown-toggle::before {
    content: "";
    border-top: 8px solid #0678be;
    border-right: 8px solid transparent;
    border-bottom: 0;
    border-left: 8px solid transparent;
    bottom: 10px;
    right: 1px;
    position: absolute;
    z-index: 1;
}

.NavClass .dropdown-toggle::after {
    content: "";
    border-top: 7px solid;
    border-right: 7px solid transparent;
    border-bottom: 0;
    border-left: 7px solid transparent;
    bottom: 7px;
    position: absolute;
}

.NavClass .logo-getz {
    width: 80%;
}

.h3ClassOrderCode{
    font-size: 0.9rem
}

@media (max-width: 991px) {
    .NavClass li a.signout {
        color: black;
        text-decoration: underline;
        padding: 0;
        background-color: unset;
    }

    .NavClass li a.link-service.active::before {
        display: none;
    }
  }