.box-station .card {
    height: 150px;
}

.box-station .card .card-header {
    background-color: rgb(255, 255, 255);
    border-radius: 3px 3px 0px 0px;
    padding: 0.5rem;
    font-size: 14px;
}

.box-station .card .card-body .icon-title {
    font-size: 16px;
    margin-top: -0.15rem;
}

.box-station .card .card-body {
    color: rgb(255, 255, 255);
    padding: 0.5rem;
    position: relative;
}

.box-station .card .time-minutes {
    font-size: 13px;
}

.box-station .card .time-minutes svg {
    margin-top: -0.25rem;
}

.box-station .card .orderCode {
    font-size: 12px;
}

.show-log {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.bg-available,
.bg-hover-available:hover {
    background-color: #7ab362;
}

.border-available {
    border: 1px solid #7ab362;
}

.bg-occupied,
.bg-hover-occupied:hover {
    background-color: #fe8905;
}

.border-occupied {
    border: 1px solid #fe8905;
}

.bg-ordering,
.bg-hover-ordering:hover {
    background-color: #006bbd;
}

.border-ordering {
    border: 1px solid #006bbd;
}

.bg-bill-pending,
.bg-hover-bill-pending:hover {
    background-color: #fb0202;
}

.border-bill-pending {
    border: 1px solid #fb0202;
}

.bg-bill-now,
.bg-hover-bill-now:hover {
    background-color: #ae1800;
}

.border-bill-now {
    border: 1px solid #ae1800;
}

.bg-billing,
.bg-hover-billing:hover {
    background-color: #ff578e;
}

.border-billing {
    border: 1px solid #ff578e;
}

.bg-paid,
.bg-hover-paid:hover {
    background-color: #b1b1b1;
}

.border-paid {
    border: 1px solid #b1b1b1;
}

.show-log:hover button {
    display: block;
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #ffffff;
}

.show-log button {
    display: none;
    position: absolute;
    top: 35%;
    left: 15%;
}

.show-log button:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #fff;
}

.revert-icon {
    fill: red !important;
    cursor: pointer;
}